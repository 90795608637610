//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VUploader from '@/components/UI/Uploader/VUploader';
import VPreloader from '@/components/UI/VPreloader';

import { PRELOADER_TYPES } from '@/utils/constants/UI';

export default {
  name: 'VImageUploader',
  components: {
    VUploader,
    VPreloader,
  },

  props: {
    innerImage: Object,
    shownPreloader: Boolean,
    id: {
      type: [Number, String],
    },
    height: {
      type: Number,
    },
    width: {
      type: Number,
    },
  },
  data: () => ({
    PRELOADER_TYPES,
  }),
  computed: {
    styles() {
      return {
        width: `${this.width}px`,
        height: `${this.height}px`,
      };
    },

    innerImageIsEmpty() {
      return this.innerImage.image?.url === null;
    },
  },

  methods: {
    onUploadImage(fileLoader) {
      this.$modal.show('CropModal', {
        file: fileLoader.getFile.value,
        imageBase64: fileLoader.getFile.base64,
        name: fileLoader.getFile.name,

        resultHandler: ({ url, id, name }) => {
          this.$emit('onUploadFiles', {
            url,
            id,
            name,
          });
        },
      });
    },
  },
}
