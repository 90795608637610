//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'VTextSwitcher',

  props: {
    options: {
      type: Array,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    displayedFirstOption: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    changeOption(id) {
      this.$emit('changeOption', id)
    },
  },
}
