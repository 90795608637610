import { render, staticRenderFns } from "./VImageUploader.vue?vue&type=template&id=54c3df12&scoped=true&"
import script from "./VImageUploader.vue?vue&type=script&lang=js&"
export * from "./VImageUploader.vue?vue&type=script&lang=js&"
import style0 from "./VImageUploader.vue?vue&type=style&index=0&id=54c3df12&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54c3df12",
  null
  
)

export default component.exports