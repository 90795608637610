//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import FileLoader from '@/utils/FileLoader/FileLoader';

export default {
  name: 'VUploader',

  props: {
    id: {
      type: String,
      required: true,
    },
  },

  mounted() {
    this.$emit('getUploaderId', this.id);
  },

  methods: {
    async onUpload(event) {
      const fileLoader = new FileLoader({
        fileList: event.target.files,
      });

      await fileLoader.init();

      document.getElementById(this.id).value = '';

      this.$emit(
        'onUploadFiles',
        fileLoader,
      );
    },
  },
}
