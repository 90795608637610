//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import VIcon from '@/components/UI/VIcon';
import VInput from '@/components/UI/VInput';
import VDropdownItem from '@/components/UI/Dropdown/VDropdownItem';

export default {
  name: 'VDropdown',
  components: {
    VIcon,
    VInput,
    VDropdownItem,
  },

  props: {
    options: {
      type: Array,
      default: () => [],
    },
    labelField: {
      type: String,
      default: 'name',
    },
  },
  computed: {
    dropdownIsEmpty() {
      return !this.options.length || this.options[0].id === null;
    },
  },

  methods: {
    emitChange(event) {
      this.$emit('selectOption', event);
      this.$emit('selectOptionId', event?.id || null);
    },

    dropdownItemIsActive(dropdownItem) {
      return !dropdownItem.name;
    },
  },

  i18n: {
    messages: {
      en: {
        'Not found': 'Not found!',
      },
      ru: {
        'Not found': 'Ничего не найдено!',
      },
    },
  },
}
