var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:[
    'v-text-switcher',
    { 'disabled': _vm.isDisabled } ]},[_c('div',{staticClass:"v-text-switcher__range"},[_c('div',{class:[
        'v-text-switcher__label',
        { 'active': _vm.options[0].isActive }
      ],on:{"click":function($event){return _vm.changeOption(_vm.options[0].id)}}},[_vm._v("\n      "+_vm._s(_vm.options[0].name)+"\n    ")]),_vm._v(" "),(_vm.displayedFirstOption)?_c('div',{class:[
        'v-text-switcher__label',
        { 'active': _vm.options[1].isActive }
      ],on:{"click":function($event){return _vm.changeOption(_vm.options[1].id)}}},[_vm._v("\n      "+_vm._s(_vm.options[1].name)+"\n    ")]):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }