//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'VDropdownItem',

  props: {
    dropdownItem: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    labelField: {
      type: String,
      default: 'name',
    },
    hasIcon: String,
  },
}
